import React from 'react';
import Helmet from 'react-helmet';
import './header.css';

export default () => (
  <header id="main-header" data-height-onload="54">
    <Helmet>
      <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `document.documentElement.className = 'js';` }}></script>
      <link
        rel="preload"
        id="divi-dynamic-css"
        href="/wp-content/et-cache/2/et-divi-dynamic-2.css?ver=1667647506"
        as="style"
        media="all"
        onload="this.onload=null;this.rel='stylesheet'"
      />
      <script type="text/javascript" src="/wp-includes/js/jquery/jquery.min.js?ver=3.6.1" id="jquery-core-js"></script>
      <script type="text/javascript" src="/wp-includes/js/jquery/jquery-migrate.min.js?ver=3.3.2" id="jquery-migrate-js"></script>

      <script
        type="text/javascript"
        src="/wp-content/plugins/sitepress-multilingual-cms/res/js/cookies/language-cookie.js?ver=4.5.12"
        id="wpml-cookie-js"
      ></script>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
      <script
        type="text/javascript"
        src="https://cookie-cdn.cookiepro.com/consent/0d5eca79-3359-4ebd-a25a-1308c5da096c/OtAutoBlock.js"
      ></script>
      <script
        src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charset="UTF-8"
        data-domain-script="0d5eca79-3359-4ebd-a25a-1308c5da096c"
      ></script>
      <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `function OptanonWrapper() {}` }}></script>

      <link rel="icon" href="/wp-content/uploads/2019/01/cropped-knowdrugs_favicon-32x32.png" sizes="32x32" />
      <link rel="icon" href="/wp-content/uploads/2019/01/cropped-knowdrugs_favicon-192x192.png" sizes="192x192" />
      <link rel="apple-touch-icon" href="/wp-content/uploads/2019/01/cropped-knowdrugs_favicon-180x180.png" />
      <meta name="msapplication-TileImage" content="/wp-content/uploads/2019/01/cropped-knowdrugs_favicon-270x270.png"></meta>
    </Helmet>
    <div class="container clearfix et_menu_container">
      <div class="logo_container">
        <span class="logo_helper"></span>
        <a href="/">
          <img
            src="/wp-content/uploads/2022/08/knowdrugs_logo_on_white.svg"
            width="144"
            height="31"
            alt="KnowDrugs"
            id="logo"
            data-height-percentage="54"
          />
        </a>
      </div>
      <div id="et-top-navigation" data-height="54" data-fixed-height="36">
        <nav id="top-menu-nav">
          <ul id="top-menu" class="nav">
            <li
              id="menu-item-522"
              class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-482 current_page_item menu-item-522"
            >
              <a href="/about/" aria-current="page">
                About
              </a>
            </li>
            <li id="menu-item-114" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-114">
              <a href="https://knowdrugs.app/#faq">FAQ</a>
            </li>
            <li id="menu-item-604" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-604">
              <a href="/donate">Donate</a>
            </li>
            <li id="menu-item-1143" class="cta menu-item menu-item-type-custom menu-item-object-custom menu-item-1143">
              <a href="https://knowdrugs.page.link/get">Get started</a>
            </li>
          </ul>
        </nav>
        <div id="et_mobile_nav_menu">
          <div class="mobile_nav closed">
            <span class="select_page">Select Page</span>
            <span class="mobile_menu_bar mobile_menu_bar_toggle"></span>
          </div>
        </div>
      </div>
    </div>
  </header>
);
