import React from 'react';
import styled from 'styled-components';

export default () => (
  <div
    id="cta"
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#212121',
      color: 'white',
      paddingTop: 56,
      paddingBottom: 56,
      marginTop: 96,
    }}
  >
    <InfoHeading>Download the App for in-depth drug analysis</InfoHeading>

    <div>
      <a href={`https://play.google.com/store/apps/details?id=cc.phk.knowdrugs&referrer=utm_source%3Dknowdrugs-web%26utm_medium%3Dbanner`} target="_blank">
        <img 
          src="https://knowdrugs.app/wp-content/uploads/2019/01/google-play-badge.svg" 
          style={{
            height: 40,
            width: 136,
            marginRight: 32,
          }}  
        />
      </a>
      <a href="https://itunes.apple.com/us/app/knowdrugs/id1152858301" target="_blank">
        <img 
          src="https://knowdrugs.app/wp-content/uploads/2019/01/appstore.svg" 
          style={{
            height: 40,
            width: 136,
          }}  
        />
      </a>
    </div>

    <InfoText>
      Browse thousands of recent drug alerts and view factsheets for more than 200 substances in the KnowDrugs App!
    </InfoText>
  </div>
)

const InfoHeading = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 62px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  margin-top: 84px;
  margin-bottom: 56px;
`

const InfoText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  margin: 0;
  max-width: 40%;
  margin-top: 32px;
  margin-bottom: 80px;
`