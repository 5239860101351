import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Header from './header';
import CTA from './shared/cta';
import Footer from './footer';
import SEO from './seo';

// import "./layout.css";
import 'typeface-inter';
import 'typeface-karla';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div id="page-container">
      <SEO />
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5MTZ2Q9" style={{ display: 'none', visibility: 'hidden' }} />
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 152,
            paddingBottom: 80
          }}
        >
          <main>{children}</main>
        </div>
        <CTA />
        <Footer />
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;

const Container = styled.div`
  flex-direction: column
  min-height: 100vh
  display: flex
`;
