import React from 'react';
import './header.css';

export default () => (
  <div id="footer" class="et_pb_section et_pb_section_11 et_pb_with_background et_section_regular">
    <div class="et_pb_row et_pb_row_14 et_pb_row_6col et-last-child">
      <div class="et_pb_column et_pb_column_1_6 et_pb_column_26  et_pb_css_mix_blend_mode_passthrough">
        <div class="et_pb_module et_pb_image et_pb_image_21 et_pb_image_sticky">
          <a href="https://knowdrugs.app/">
            <span class="et_pb_image_wrap ">
              <img
                decoding="async"
                loading="lazy"
                width="144"
                height="31"
                src="/wp-content/uploads/2022/10/knowdrugs_logo_on_black.svg"
                alt=""
                title="knowdrugs_logo_on_black"
                class="wp-image-1252"
              />
            </span>
          </a>
        </div>
        <div class="et_pb_module et_pb_text et_pb_text_33  et_pb_text_align_left et_pb_bg_layout_light">
          <div class="et_pb_text_inner">
            <p>© 2015-2022 Plant Product Design UG</p>
          </div>
        </div>
      </div>

      <div class="et_pb_column et_pb_column_1_6 et_pb_column_27  et_pb_css_mix_blend_mode_passthrough">
        <div class="et_pb_module et_pb_text et_pb_text_34  et_pb_text_align_left et_pb_bg_layout_light">
          <div class="et_pb_text_inner">
            <span class="text__white">
              <a href="https://knowdrugs.app/#drugchecking" title="KnowDrugs Drug Checking" class="text__white">
                Drug Checking
              </a>
            </span>
            <p>
              <span class="text__white">
                <a href="https://knowdrugs.app/#substanceinfo" title="KnowDrugs Substance Information" class="text__white">
                  Substance Information
                </a>
              </span>
            </p>
            <p>
              <span class="text__white">
                <a href="https://knowdrugs.app/#saferuse" title="KnowDrugs Harm Reduction" class="text__white">
                  Harm Reduction
                </a>
              </span>
            </p>
            <p>
              <span class="text__white">
                <a href="https://knowdrugs.app/#counselling" title="KnowDrugs Drug Counselling" class="text__white">
                  Drug Counselling
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>

      <div class="et_pb_column et_pb_column_1_6 et_pb_column_28  et_pb_css_mix_blend_mode_passthrough">
        <div class="et_pb_module et_pb_text et_pb_text_35  et_pb_text_align_left et_pb_bg_layout_light">
          <div class="et_pb_text_inner">
            <p>
              <span class="text__white">
                <a href="https://knowdrugs.app/about" title="About KnowDrugs" class="text__white">
                  About KnowDrugs
                </a>
              </span>
            </p>
            <p>
              <span class="text__white">
                <a href="https://knowdrugs.app/donate" title="Support the KnowDrugs Project" class="text__white">
                  Support Us
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>

      <div class="et_pb_column et_pb_column_1_6 et_pb_column_29  et_pb_css_mix_blend_mode_passthrough">
        <div class="et_pb_module et_pb_text et_pb_text_36  et_pb_text_align_left et_pb_bg_layout_light">
          <div class="et_pb_text_inner">
            <p>
              <span class="text__white">
                <a href="https://knowdrugs.app/privacy-policy" title="Privacy Policy of the website knowdrugs.app" class="text__white">
                  Privacy Policy
                </a>
              </span>
            </p>
            <p>
              <span class="text__white">
                <a
                  href="https://knowdrugs.app/terms-of-service"
                  title="Terms of Service of the mobile application KnowDrugs"
                  class="text__white"
                >
                  Terms of Service
                </a>
              </span>
            </p>
            <p>
              <span class="text__white">
                <a href="https://knowdrugs.app/imprint" title="Imprint of the website knowdrugs.app" class="text__white">
                  Imprint
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>

      <div class="et_pb_column et_pb_column_1_6 et_pb_column_30  et_pb_css_mix_blend_mode_passthrough">
        <ul class="et_pb_module et_pb_social_media_follow et_pb_social_media_follow_0 clearfix  et_pb_bg_layout_dark">
          <li class="et_pb_social_media_follow_network_0 et_pb_social_icon et_pb_social_network_link  et-social-instagram">
            <a href="https://instagram.com/know_drugs/" class="icon et_pb_with_border" title="Follow on Instagram" target="_blank">
              <span class="et_pb_social_media_follow_network_name" aria-hidden="true">
                Follow
              </span>
            </a>
          </li>
          <li class="et_pb_social_media_follow_network_1 et_pb_social_icon et_pb_social_network_link  et-social-facebook">
            <a href="https://www.facebook.com/knowdrugs/" class="icon et_pb_with_border" title="Follow on Facebook" target="_blank">
              <span class="et_pb_social_media_follow_network_name" aria-hidden="true">
                Follow
              </span>
            </a>
          </li>
          <li class="et_pb_social_media_follow_network_2 et_pb_social_icon et_pb_social_network_link  et-social-twitter">
            <a href="https://twitter.com/know_drugs" class="icon et_pb_with_border" title="Follow on Twitter" target="_blank">
              <span class="et_pb_social_media_follow_network_name" aria-hidden="true">
                Follow
              </span>
            </a>
          </li>
        </ul>
        <div class="et_pb_module et_pb_code et_pb_code_1"></div>
      </div>

      <div class="et_pb_column et_pb_column_1_6 et_pb_column_31  et_pb_css_mix_blend_mode_passthrough et-last-child">
        <div class="et_pb_module et_pb_image et_pb_image_22">
          <a
            href="https://play.google.com/store/apps/details?id=cc.phk.knowdrugs&#038;referrer=utm_source%3Dknowdrugs.app%26utm_medium%3Dfooter"
            target="_blank"
          >
            <span class="et_pb_image_wrap ">
              <img
                decoding="async"
                src="https://knowdrugs.app/wp-content/uploads/2019/01/google-play-badge.svg"
                alt=""
                title=""
                class="wp-image-69"
              />
            </span>
          </a>
        </div>

        <div class="et_pb_module et_pb_image et_pb_image_23">
          <a href="https://itunes.apple.com/us/app/knowdrugs/id1152858301" target="_blank">
            <span class="et_pb_image_wrap ">
              <img
                decoding="async"
                src="https://knowdrugs.app/wp-content/uploads/2019/01/appstore.svg"
                alt=""
                title=""
                class="wp-image-70"
              />
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
);
